import axios from 'axios';

async function UpdateSW() {
    try {
        const response = await axios.get("/app.config.json?nc=" + Math.random())
        localStorage.setItem("_settings", JSON.stringify(response.data))

        if (localStorage.getItem("_version") != response.data?.v && response.data?.v) {
            console.log("update available")


            const cacheNames = await caches.keys();
            for (let cacheName of cacheNames) {
                console.log("cd:", cacheName)
                await caches.delete(cacheName);
            }
            if (typeof window.AndroidApp != "undefined") {
                navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                    for (const registration of registrations) {
                        try { console.log(await registration.unregister()); } catch (e) { console.error(e) }
                    }
                });
            }
            localStorage.setItem("_version", response.data?.v)
            window.location.reload(true);

        }

        return response.data;
    } catch (e) {
        if (localStorage.getItem("_settings")) {
            return JSON.parse(localStorage.getItem("_settings"));
        }
        return false;
    }

}

export default UpdateSW;
