
import { lazy, useState } from 'react';
import UpdateSW from './inc/UpdateSW';
import { useEffect } from "react";
const App = lazy(() => import('./App'));
const Land = lazy(() => import('./Land'));
const Connect = lazy(() => import('./Connect'));
const LkMain = lazy(() => import('./LkMain'));
const AppAuth = lazy(() => import('./AppAuth'));
const LkApp = lazy(() => import('./LkApp'));
const AcpMain = lazy(() => import('./Acp'));
const AcpAuth = lazy(() => import('./AcpAuth'));
const AppWelcome = lazy(() => import('./AppWelcome'));
const TwaStart = lazy(() => import('./TwaStart'));
const PaySuccess = lazy(() => import('./PaySuccess'));



const Task = lazy(() => import('./Task'));

function Route(args) {
  const [Settings, setSettings] = useState()
  const [GL_User, seGL_User] = useState(false)

  async function UpdateS() {
    setSettings(await UpdateSW());
  }
  useEffect(() => {
    UpdateS();

  }, [])
  if (Settings) {

    switch (args.page) {
      case "main":
        return (<App ApiUrl={Settings.base_back} GL_User={GL_User} seGL_User={seGL_User} Settings={Settings} />);

      case "my-land":
        return (<Land ApiUrl={Settings.base_back} Settings={Settings} />);

      case "my-connect":
        return (<Connect ApiUrl={Settings.base_back} Settings={Settings} />);

      case "my":
        return (<LkMain ApiUrl={Settings.base_back} Settings={Settings} />);

      case "AppAuth":
        return (<AppAuth ApiUrl={Settings.base_back} Settings={Settings} />);

      case "AppWelcome":
        return (<AppWelcome ApiUrl={Settings.base_back} Settings={Settings} />);

      case "my-app":
        return (<LkApp ApiUrl={Settings.base_back} Settings={Settings} />);
      case "my-task":
        return (<Task ApiUrl={Settings.base_back} Settings={Settings} />);

      case "acp-app":
        return (<AcpMain ApiUrl={Settings.base_back} Settings={Settings} />);

      case "acp-auth":
        return (<AcpAuth ApiUrl={Settings.base_back} Settings={Settings} />);
      case "TwaStart":
          return (<TwaStart ApiUrl={Settings.base_back} Settings={Settings} />);
      case "PaySuccess":
          return (<PaySuccess ApiUrl={Settings.base_back} Settings={Settings} />);


          

        



    }
  }
}
export default Route;

